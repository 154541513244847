@charset "utf-8";

.closecross {
  position: absolute;
  top: 26px;
  right: 48px;
  font-size: 30px;
  cursor: pointer;
}

.shadowed-block {
  -webkit-box-shadow: -2px 0px 25px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 0px 25px -2px rgba(0,0,0,0.75);
  box-shadow: -2px 0px 25px -2px rgba(0,0,0,0.75);
  padding: 0 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  flex: 1;
}
.grey-bg-filter {
  margin-top: 45px;
}
.group-gray-bg {
  background: none;
  border: 1px solid grey;
  margin-top: 20px;
}

.toolbar {
  display: flex;
  width: 100%;
  padding: 15px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    > * {
      //margin-bottom: 10px;
      text-align: center;
      margin: 10px!important;
      button{
        width: 100%;
      }
    }
  }


  > * {
    //margin-bottom: 10px;
    text-align: center;
    margin: 20px;
  }
}

.sidebar {
  background-color: #f5f5f5;
  flex: 0 0 262px;

  > .custom_dropdown {
    margin-bottom: 38px;
    margin-left: 45px;
  }

  > .custom_dropdown_label {
    margin-bottom: 11px;
    margin-left: 45px;

    //font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-bottom: 5px;
    border-bottom: 2px solid #dedede;
  }
}

.sidebarmenu {
  display: flex;
  flex-direction: column;
  //font-family: "Poppins";
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  color: #9f9f9f;
  margin-left: 30px;
  margin-right: 35px;
  margin-bottom: 10px;

  > a {
    padding: 10px;
    cursor: pointer;
  }

  > a.active {
    border-radius: 3px;
    background-color: $secondary-color-01;
    color: white !important;
  }
}

.custom_dropdown {
  .btn {
    //font-family: "Poppins";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    border-radius: 3px;
    border: solid 1px #c4c8ce;
    background-color: white;
  }
}

.invitebutton {
  //font-family: "Poppins";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: $secondary-color-01;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

.indgroup {
  border-radius: 3px;
  border: solid 1px $secondary-color-01;
  background-color: #ffffff;

  .btn {
    padding: 5px 10px;
    //font-family: HelveticaNeue;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background-color: #fff;
    color: $secondary-color-01;
  }

  .btn.active {
    background-color: $secondary-color-01 !important;
    color: #fff !important;

  }
}

.popupcontainer {
  padding: 25px 40px;
  font-size: 14px;
  font-weight: 500;
  color: #525359;

  @media (max-width: 414px)  {
    padding: 25px 15px;
  }
}

.savebutton {
  border-radius: 3px;
  background-color: $secondary-color-01;
  padding: 12px 60px;
  color: white;
}

.horizontaldivider {
  height: 2px;
  background-color: #e7e9f2;
  margin: 30px -40px;
}

.thumb {
  width: 100%;
  padding-bottom: 100%;
  background-position: center center;
  background-size: cover;
  /*height: 80px;*/
}

.thumb-del {
  color: $danger;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  top: 6px;
  right: 20px;
  line-height: 20px;
  font-size: 20px;
  cursor: pointer;
}

.msg-thumb-container {
  margin-top: 20px;
  margin-right: -10px;
}

.msg-thumb {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  width: auto;
  height: 80px;
}

.msg-thumb-tiny {
  @extend .msg-thumb;
  height: 40px;
}

.dropboxcontainer {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: dashed 1px #d0d6e3;
  margin-top: 7px;
}

.dropimagelist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropimagewrapper {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 10px;
}

.dropimage {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.dropcaption {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropsubtext {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #949db0;
}

.redclosicon {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
  background-color: #d0021b;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1px;
  cursor: pointer;
  padding-bottom: 1px;
  border: solid 2px #ffffff;
}

