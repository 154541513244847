// colors
// primary
$primary-color: #0078ff;
// secondary
$secondary-color-01: #ffb702;
$secondary-color-02: #dd2d4a;
$secondary-color-03: #3f444a;
// Tertiary
$tertiary-color-01: #3f348f;
$tertiary-color-02: #ff7d00;
$tertiary-color-03: #60d394;
