@charset "utf-8";

* {
  box-sizing: border-box;
  outline: none !important;
}
@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
  url('fonts/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
  url('fonts/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
  url('fonts/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-Light.woff2') format('woff2'),
  url('fonts/SpaceGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
  url('fonts/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter-Regular.woff2') format('woff2'),
  url('fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


html,
body {
  height: 100%;
  margin: 0;
  font-family: Inter, open-sans, sans-serif;

  &.result-page {
    overflow: hidden;
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

input {
  outline: none !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

table {
  width: 100%;
  padding: 15px;
  //min-width: 750px;

  tr {
    border-bottom: 1px solid #d8d8d8;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  thead > tr {
    border-bottom: 1px solid #828282;
  }

  td {
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  th {
    text-align: center;
    padding: 7px;
    white-space: nowrap;
  }
}

h2 {
  display: block;
  //font-family: "Poppins";
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

h3 {
  //font-family: "Poppins";
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 20px 20px 20px 0px !important;
}
.searchfield {
  border-radius: 3px;
  border: solid 1px #c4c8ce;
  background-color: #ffffff;
  //font-family: "Poppins";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding: 5px 5px 5px 35px;
  text-align: left !important;
  background-image: url("/assets/images/search.png");
  background-size: 20px 20px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  width: 300px;
}
.searchfield::placeholder {
  color: #a9b2bd;
}
.menu-grey {
  background: #ecebeb;
}
app-results,
app-contact,
app-position,
app-positions,
app-drivers-positions,
app-vehicles-position,
app-gamification {
  display: flex;
  flex: 1;
}

app-drivers {
  display: flex;
  flex: 1;
  .container {
    display: flex;
  }
}

.leaflet-popup {
  .leaflet-popup-content-wrapper {
    background-color: black !important;
    padding: 0;
    .leaflet-popup-content {
      margin: 0 !important;
      width: auto !important;
      .leaflet-popup-content-header {
        background-color: #313131;
        padding: 15px;
        color: white;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        strong {
          text-transform: uppercase;
          font-size: 20px;
        }
      }
      .leaflet-popup-content-body {
        background-color: #000000;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 13px 19px;

        .leaflet-popup-body-show {
          color: white;
          font-size: 15px;
          display: flex;

          label {
            font-size: 17px;
            margin-right: 10px;
            white-space: nowrap;
            line-height: 20px;
          }
        }

        #request-btn:not([disabled]) {
          color: white;
          background: #f5a623;
        }
        #request-btn:not([disabled]):focus, #request-btn:not([disabled]).focus {
          box-shadow: 0 0 0 0.2rem #f5a62361;
        }
        #request-btn:not([disabled]):hover, #request-btn:not([disabled]).hover {
          box-shadow: 0 0 0 0.2rem #f5a62361;
          background: #f3b34d;
        }
      }
    }
  }
  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background-color: black !important;
    }
  }
  .leaflet-popup-close-button {
    display: none;
  }
}

.video-js .vjs-big-play-button {
  left: 40% !important;
  top: 40% !important;
  width: 20%;
  height: 20%;
}

.video-js .vjs-play-control:before {
  top: 20% !important;
  // content: "\f101";
  font-size: 48px;
}

.marker-pin {
  width: 40px;
  height: 40px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
// to draw white circle
.marker-pin::after {
  content: "";
  width: 30px;
  height: 30px;
  margin: 5px 0 0 5px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}
// to align icon
.custom-div-icon span {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}
