@charset "utf-8";

app-user,
app-leaderboards {
  height: 100%;
  display: flex;
}

app-leaderboards {
  flex: 1;
}

.scoreblock {
  border-radius: 2px;

  width: 70px;
  height: 35px;
  //font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

