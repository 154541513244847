@charset "utf-8";

.gamecontainer > *:nth-child(3) {
  flex: 1;
  padding-left: 35px;
  padding-right: 35px;

}

.prosnconscontainer {
//   display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  width: calc(100% - 100px);
  margin: 0 50px;
  overflow-x: auto;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}

.prosnconstable {
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  //min-width: 250px;
  flex: 1;
  flex-wrap: wrap;
  align-items: stretch;
}

.prosnconstitle {
  padding: 4px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid black;
}


.prosnconsitemtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #fff;
  padding: 10px;
}

.pronconsitem {
  font-family: 'Open Sans';
  margin: 3px 10px;
  font-size: 16px;
  font-weight: 600;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  background-color: black;
  border-radius: 4px;
  border: solid 1px #fff;
  cursor: pointer;
  &:hover{
    border: solid 1px #fca000;
    color: #fca000;
    .prosnconstable{
      color: #fca000;

    }
    .prosnconsitemtitle{
      border-left: 1px solid #fca000;

    }
  }
}
