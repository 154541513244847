@charset "utf-8";

.message-list-item {
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 16px;
  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #b7bec7;

}

.message-timestamp {
  font-size: 0.9em;
  font-weight: bold;
}

.message {
  //@extend .py-4;
  //@extend .px-5;
  @extend .px-4;
  @extend .py-2;

  display: flex;

  //background-color: $light;
  //
  //margin-right: -15px;
  //margin-left: -15px;

  border-radius: 4px;
  border: solid $light 1px;


}

.message-body {
  flex: 1;
  width: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.reactions-list {
  display: flex;
  flex: 1;

  .message-body {
    @extend .mb-4;
    @extend .p-2;
    border: solid $light 2px;

  }

}

.reaction {
  flex: 1;
}

.avatar-big {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: white;
}

.avatar-small {
  @extend .avatar-big;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.avatar-tiny {
  @extend .avatar-big;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.react-form {
  position: sticky;
  bottom: 0;
}
