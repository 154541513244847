@charset "utf-8";

/* Do NOT add global styles to this file, only import other style files */

@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

@import "vars";

/* Importing Bootstrap SCSS file. */
@import '../../node_modules/bootstrap/scss/bootstrap';

/* Base styles  */
@import 'base';

/* Individual components styles */
@import 'topnav';
@import 'pagination';

/* Helper styles */
@import 'helpers';

/* Video JS styles*/
@import '~video.js/dist/video-js.css';

/* Almost all styles here */
@import 'blocks';

/* Overrides for module styles like bootstrap, etc... */
@import 'overrides';

/* Page specific styles */
@import 'pages/forum';
@import 'pages/leaderboards';
@import 'pages/gamification';
@import 'pages/drivers';
@import 'pages/positions';

