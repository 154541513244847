@charset "utf-8";
@import "variables";

pagination-controls li {
  width: 30px;
  height: 25px;
  border: 1px solid #000;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  color: $secondary-color-03;
  margin-right: -1px !important;

  &.current {
    color: white !important;
    background-color: black !important;
    border-top: none !important;
    border-bottom: none !important;
  }
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
  background: white !important;
}

pagination-controls ul {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 7px;
}

pagination-controls .small-screen {
  display: none !important;
}

.pagination-previous {
  border-bottom-left-radius: 3px;
}

.pagination-next {
  border-bottom-right-radius: 3px;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-next.disabled::after,
.ngx-pagination .pagination-previous.disabled::before {
  display: none !important;
}
