@charset "utf-8";

.pointer {
  cursor: pointer !important;
}

.tablescroll {
  width: 100%;
  overflow-x: auto;
}

.gray500 {
  color: gray("500");
}

.blink {
  animation: blinker 0.75s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.filler {
  flex: 1;
  @media (max-width: 998px) {
    display: none;
  }
}


