@charset "utf-8";
@import "variables";

.modal-dialog {
  //max-width: 650px;
}

.dropdown {
  .btn {
    text-align: left;
    padding-right: 25px;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 8px;
    top: calc(50% - 3px);
  }
}

button[aria-label="Close"]:focus {
  outline: none;
}

.cdk-overlay-container li a {
  display: block;
  width: 100%;
  padding: 5px 10px;
  //cursor: pointer;
}

.cdk-overlay-container li a:hover {
  background-color: $secondary-color-01;
}

.group-gray-bg input {
  border-radius: 0px;
  background-color: transparent;
  border: none!important;
  color: #c5c5c5;
}
