@charset "utf-8";
@import "variables";

nav.topnav {
  align-items: stretch;

  min-width: 305px;
  min-height: 60px;

  @extend .p-0;

  .navbar-brand {
    @extend .pl-2;

    margin-right: 0.5rem;

    @include media-breakpoint-up(lg) {
      padding-left: 1.5rem !important;
    }

    img {
      height: 44px;
      margin-top: 4px;
      margin-bottom: 4px;
      width: auto;
    }

    @media (max-width: 440px) {
      img {
        width: 90px;
      }
    }

    @media (max-width: 365px) {
      img {
        width: 70px;
      }
    }

    @media (max-width: 345px) {
      img {
        display: none;
      }
    }

  }

  .navbar-nav {

    align-items: stretch;
    align-self: stretch;
    //background: #ECEBEB;
    width: 100%;
    display: flex;
    justify-content: center;

    .nav-link {
      @extend .px-2;
      @extend .py-0;
      text-transform: uppercase;
      vertical-align: middle;

      line-height: 48px;
      margin: 0 0.3em;
      color: $secondary-color-03 !important;

      @include media-breakpoint-up(xl) {
        font-size: 1.1em !important;
      }

      @media (min-width: 1270px) {
        font-size: 1.2em !important;
      }

      @include media-breakpoint-up(lg) {
        letter-spacing: -0.5px;
        line-height: 60px;
      }

      &:hover {
        /*color: #f5a623 !important;*/
      }

      &.active {
        /*background-color: rgba(126, 126, 126, 0.78);*/
        background-color: $secondary-color-03;
        /*color: #f5a623 !important;*/

      }
    }
  }

  .logincontainer {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-right: 10px;
    justify-self: flex-end;

    .avatar-small {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .toggle {
      padding-top: 0;
      padding-bottom: 0;
      border-width: 0;
      color: $secondary-color-03;
      height: 60px;
      padding-right: 15px;
      @include media-breakpoint-up(lg) {
        padding-right: 25px;
      }
    }

  }

  .lh60 {
    line-height: 60px;
  }

}
